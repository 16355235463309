var slider           = require('./scripts/slider.js');

(function ($, root, undefined) {

    $(function () {

        'use strict';

        /******************************************************
         *    INIT ALL FUNCTIONS
         *******************************************************/ 
        initScrolTop();
        initMobileMenuOpen();
        goBack();
        initProductSlider();
        initTestimonialSlider();
        initWooQuantityButtons();
        initOpenTestAddForm();
        initOpenContactFormPopup();
        initTestAddToAccount();

        function initTestAddToAccount() {
            $('#submit-add-test').submit(function(event) {

                var testNumber  = $('input[name="test-number-added"]').val();
                var userId      = $('input[name="current_user_id"]').val();
                
                if( testNumber == '' ) {
                    $('input[name="test-number-added"]').addClass('error');

                } else {
                    $('input[name="test-number-added"]').removeClass('error');

                    var formData = {
                        'from'     : 'testNumber',
                        'number'   : testNumber,
                        'user'     : userId
                    };
                    
                    $.ajax({
                        type        : 'POST',
                        url         : '/wp-content/themes/zex/ajax/test.php',
                        data        : formData,
                        dataType    : 'json',
                        encode      : true
                    })
                        // using the done promise callback
                        .done(function(data) {
                            if(data['success'] == true) {
                                location.reload();
                            } else {
                                $('input[name="test-number-added"]').addClass('error');
                            }
                        })
                    
                        .fail(function(data) {
                            console.log(data); 
                        });
                    

                }

                event.preventDefault();
            });
        }

        function initOpenContactFormPopup() {
            $('.open-popup').on('click', function(event) {

                $('.contact-popup').addClass('active');

                event.preventDefault();
            });

            $('.close-popup').on('click', function(event) {

                $('.contact-popup').removeClass('active');

                event.preventDefault();
            });
        }

        function initOpenTestAddForm() {
            $('.add-test').on('click', function(event) {

                $('.test-add-popup').addClass('active');
    
                event.preventDefault();
            });
    
            $('.close-popup-test').on('click', function(event) {
    
                $('.test-add-popup').removeClass('active');
    
                event.preventDefault();
            });
        }

        $("#slider").lightSlider({
            item: 1,
            slideMove: 1,
            loop:false,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed:600,
            controls: false,
            adaptiveHeight: true
        });

        function initProductSlider() {
            var productSlider = $(".product-slider").lightSlider({
                item: 4,
                slideMove: 1,
                loop:false,
                easing: 'cubic-bezier(0.25, 0, 0.25, 1)', 
                speed:600,
                controls: false,
                pager: false,
                adaptiveHeight:true,
                responsive : [
                    {
                        breakpoint:992,
                        settings: {
                            item:3
                          }
                    },
                    {
                        breakpoint:768,
                        settings: {
                            item:2,
                        }
                    },
                    {
                        breakpoint:480,
                        settings: {
                            item:1,
                        }
                    }
                ]
            });
    
            $('.products-section .lSPrev').click(function () {
                productSlider.goToPrevSlide();
            });
            $('.products-section  .lSNext').click(function () {
                productSlider.goToNextSlide();
            });
        }
        
        function initTestimonialSlider() {
            var testimonialSlider = $(".testimonial-slider").lightSlider({
                item: 3,
                slideMove: 1,
                loop:false,
                easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                speed:600,
                controls: false,
                pager: false,
                adaptiveHeight:true,
                slideMargin:30,
                responsive : [
                    {
                        breakpoint:992,
                        settings: {
                            item:2
                        }
                    },
                    {
                        breakpoint:768,
                        settings: {
                            item:1,
                        }
                    }
                ]
            });
    
            $('.testimonials .lSPrev').click(function () {
                testimonialSlider.goToPrevSlide();
            });
            $('.testimonials .lSNext').click(function () {
                testimonialSlider.goToNextSlide();
            });
        }

        /******************************************************
         *    OPEN MOBILE MENU
         *******************************************************/ 
        function initMobileMenuOpen() {
            $('.mobile-navigation').on('click', function(event) {

                $(this).toggleClass('open');
                $('.mob-nav').toggleClass('active');
                $('body, html').toggleClass('overflow');
    
                event.preventDefault();
            });
        }
        
        /******************************************************
        *    SCROLL TO TOP
        *******************************************************/ 
        function initScrolTop() {
            $('#scroll-top').on('click', function(event) {

                $('html, body').animate({
                    scrollTop: 0
                }, 1000);

                return false;
            });
        }

        function goBack() {
            $('.goBack, .go-back-one').on('click', function(event) {
                window.history.back();

                event.preventDefault();
            });
        }

        /******************************************************
         *    WOOCOMMERCE QUANTITY BUTTON + - ADD
         *******************************************************/ 
        function initWooQuantityButtons() {
            $('.quantity').append('<span class="qty-m"></span>');
            $('.quantity').append('<span class="qty-u"></span>');

            $(document).on('click', '.qty-m', function(event) {

                var number = $(this).parent().find('.qty').val();
                
                if( number == 1 ) {

                } else {
                    $(this).parent().find('.qty').val(number - 1);
                }

                event.preventDefault();
            });

            $(document).on('click', '.qty-u', function(event) {

                var number = $(this).parent().find('.qty').val();
                var numberis = parseInt(number) + 1;

                $(this).parent().find('.qty').val(numberis);
                
                event.preventDefault();
            });
        }

    });

    $(window).on("load", function() {
        
    });

    // Lang switcher
    $( ".language-selector" ).hover(function() {
        $( ".lang-dropdown" ).toggle();
        $( ".lang-toggle" ).toggleClass('opened');
    });
    
    // Lang switcher mob
    $( ".under-mob-menu .language-selector" ).click(function() {
        $( ".lang-dropdown" ).toggle();
        $( ".lang-toggle" ).toggleClass('opened');
    });

    $(document).mouseup(function(e){
        var langContainer = $(".lang-dropdown");
        if(!langContainer.is(e.target) && langContainer.has(e.target).length === 0){
            langContainer.hide();
        }
    });

})(jQuery, this);    